<template>
  <div
      :style="{height:height}"
  />
</template>

<script>
import echarts from 'echarts'
require('echarts/theme/macarons') // echarts theme
// import { debounce } from '@/utils'
// import { getChartData } from '@/api/visits'

export default {
  name: 'spreadCharInner',
  props: {
    xData:{
      type: Array,
      default(){
        return [];
      }
    },
    yDatas:{
      type: Array,
      default(){
        return [];
      }
    },
    chartLegends:{
      type: Array,
      default(){
        return [];
      }
    }
  },
  data() {
    return {
      chart: null,
      height: '750px'
    }
  },
  beforeDestroy() {
    if (!this.chart) {
      return
    }
    this.chart.dispose()
    this.chart = null
  },
  watch:{
    xData(val){
      if(val){
        this.initChart();
      }
    }
  },
  methods: {
    setOptions() {
      let yDataList = [];
      for (let i = 0; i < this.yDatas.length; i++) {
        let rgbColor = `rgb(${parseInt(Math.random()*255)},${parseInt(Math.random()*255)},${parseInt(Math.random()*255)})`
        yDataList.push({
          name: this.chartLegends[i],
          // smooth: true, //  曲线
          smooth: false, //  折线
          type: 'line',
          itemStyle: {
            normal: {
              color: rgbColor,
              lineStyle: {
                color: rgbColor,
                width: 2
              },
              /*区域颜色*/
              /*areaStyle: {
                color: '#f3f8ff'
              }*/
            }
          },
          data: this.yDatas[i].trendList,
          animationDuration: 2800,
          animationEasing: 'quadraticOut'
        });
      }
      this.chart.setOption({
        legend:{
          data: this.chartLegends,
        },
        xAxis: {
          data: this.xData,
          boundaryGap: false,
          axisTick: {
            show: false
          }
        },
        grid: {
          left: 10,
          right: 10,
          bottom: 20,
          top: 60,
          containLabel: true,
        },
        tooltip: {
          trigger: 'axis',
          // order: 'valueDesc',//降序 5.0版本才开始有
          axisPointer: {
            type: 'cross'
          },
          padding: [5, 10],
          formatter(params){
            let newParams = [];
            let tooltipString = [];
            newParams = [...params];
            newParams.sort((a,b) => {return b.value -a.value });
            newParams.forEach((p) => {
              const cont = p.marker + ' ' + p.seriesName + ': ' + p.value + '<br/>';
              tooltipString.push(cont);
            })

            return tooltipString.join('');
          }
        },
        yAxis: {
          axisTick: {
            show: false
          }
        },
        series: yDataList,
      })
    },
    initChart() {
      this.chart = echarts.init(this.$el, 'macarons')
      this.setOptions();
    }
  },
}
</script>
