<template>
  <div class="app-container summary-page traderecord-page">
    <el-tabs
        v-model="tabName"
        type="border-card"
    >
      <el-tab-pane label="数据" name="data">
        <spread-data
          v-if="tabName == 'data'"
        ></spread-data>
      </el-tab-pane>
      <el-tab-pane
          label="图表"
          name="chart"
      >
        <spread-chart
            v-if="tabName == 'chart'"
        ></spread-chart>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import spreadChart from './chart/index'
import spreadData from './data/index'
export default {
  name:'spreadHome',
  components: {
    spreadData,
    spreadChart,
  },
  props:{
  },
  data() {
    return {
      tabName: 'data',
    }
  },
  created() {
  },
  methods: {
  }
}
</script>

<style lang="less">
  .spread {

  }
</style>
