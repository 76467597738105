<template>
  <div class="app-container summary-page traderecord-page">
    <eHeader
        ref='header'
        :query="query"
        @clear="clear"
        @toQuery="toQuery"
    />
    <spread-chart-inner
        ref="spreadchart"
        :chart-legends="chartInfo.infoSet"
        :x-data="chartInfo.xAxis"
        :y-datas="chartInfo.yAxis"
    ></spread-chart-inner>
  </div>
</template>

<script>
import spreadChartInner from '@/components/stat/spread/spreadChart'
import checkPermission from '@/utils/permission'
import { parseTime } from '@/utils/index'
import eHeader from '@/components/stat/spread/chartHeader'
import { initData } from '@/api/data'
export default {
  name:'spreadChart',
  components: {
    eHeader,
    spreadChartInner,
  },
  props:{
  },
  data() {
    return {
      loading: false,
      chartInfo: {
        xAxis: [],
      },
      url: 'crm/getSpreadTrendChart',
      params: {},
      query: {
      },
    }
  },
  methods: {
    checkPermission,
    queryData() {
      this.url = '/crm/getSpreadTrendChart'
      this.params = {
        page: this.page,
        size: this.size,
      }
      this.params.symbol = this.query.symbol;
      this.params.startTime = parseTime(this.query.startTime);
      this.params.endTime = parseTime(this.query.endTime);
      initData(this.url, this.params).then(res => {
        // 需要处理数据
        this.chartInfo = res;
        // this.$refs.spreadchart.initChart();
        setTimeout(() => {
          this.loading = false
        }, this.time)
      }).catch(err => {
        this.loading = false
      })
    },
    clear(){
      this.query = {};
    },
    toQuery(){
      this.queryData();
    },
  }
}
</script>

<style lang="less">
.spread {

}
</style>
